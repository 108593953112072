<template>
  <div>
    <!-- 视频入口 -->
    <div>
      <div class="titile">视频会议</div>
      <div>
        <router-link to="/zhengxie/video">
        <span class="button" style="margin-left:7%;background: #5599fe;">
          <van-icon name="video" class="icon" />
          <span>发起会议</span>
        </span>
        </router-link>
        <router-link to="/zhengxie/video">
        <span class="button" style="margin-left:6%;background: #5db465;">
          <van-icon name="add" class="icon" />
          <span>加入会议</span>
        </span>
        </router-link>
      </div>
    </div>
    <!-- 今日记录 -->
    <div style="margin-top:50px">
      <div class="titile" style="font-size:18px">8月24日 今天</div>
      <div class="empty">────── 暂无会议 ──────</div>
    </div>
    <!-- 历史记录 -->
    <div style="margin-top:50px">
      <div class="titile" style="font-size:18px">历史记录</div>
      <div class="record">
        <span class="record_img">
          <img src="../../assets/icon_intermediary_unselected@2x.png" alt="">
        </span>
        <div class="record_title" style="display: inline-block;">
          <div>张富贵发起的视频会议</div>
          <div style="color:#999;font-size:14px;margin-top:5px">
            <span>9月1日 09:26-10:17</span>
            <span>|</span>
            <span>3人</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped>
.titile {
  font-size: 22px;
  font-weight: 700;
  margin: 20px 15px;
}
.icon {
  display: inline-block;
  width: 100%;
  font-size: 30px;
}
.button {
  display: inline-block;
  width: 40%;
  padding: 15px 0px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
}
.empty {
  text-align: center;
  color: #999;
  font-size: 14px;
}
.record_img {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  background: #f8f9f9;
}
.record_img img{
  margin: 8px;
  width: 60%;
}
.record_title {
  padding: 15px;
}
.record {
  margin-left: 20px;
}
</style>